var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"create-build-server-sidebar","visible":_vm.isCreateBuildServerSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-create-build-server-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("New Build Server")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"form",staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"Server Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Server Name","label-for":"server-name"}},[_c('b-form-input',{attrs:{"id":"server-name","autofocus":"","trim":"","state":errors.length > 0 ? false:null,"placeholder":"Server Name"},model:{value:(_vm.fetchData.name),callback:function ($$v) {_vm.$set(_vm.fetchData, "name", $$v)},expression:"fetchData.name"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Server IP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Server Ip","label-for":"server-ip"}},[_c('b-form-input',{attrs:{"id":"server-ip","autofocus":"","trim":"","state":errors.length > 0 ? false:null,"placeholder":"Server Ip"},model:{value:(_vm.fetchData.ip),callback:function ($$v) {_vm.$set(_vm.fetchData, "ip", $$v)},expression:"fetchData.ip"}})],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Unity Username","label-for":"unity-username"}},[_c('b-form-input',{attrs:{"id":"unity-username","autofocus":"","trim":"","placeholder":"Unity Username"},model:{value:(_vm.fetchData.unity_username),callback:function ($$v) {_vm.$set(_vm.fetchData, "unity_username", $$v)},expression:"fetchData.unity_username"}})],1),_c('b-form-group',{attrs:{"label":"Unity Password","label-for":"unity-password"}},[_c('b-form-input',{attrs:{"id":"unity-password","autofocus":"","trim":"","placeholder":"Unity Password"},model:{value:(_vm.fetchData.unity_password),callback:function ($$v) {_vm.$set(_vm.fetchData, "unity_password", $$v)},expression:"fetchData.unity_password"}})],1),_c('b-form-group',{attrs:{"label":"Unity Serial","label-for":"unity-serial"}},[_c('b-form-input',{attrs:{"id":"unity-serial","autofocus":"","trim":"","placeholder":"Unity Serial"},model:{value:(_vm.fetchData.unity_serial),callback:function ($$v) {_vm.$set(_vm.fetchData, "unity_serial", $$v)},expression:"fetchData.unity_serial"}})],1),_c('div',{staticClass:"mb-1"},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"state":_vm.state,"value":true},model:{value:(_vm.fetchData.android),callback:function ($$v) {_vm.$set(_vm.fetchData, "android", $$v)},expression:"fetchData.android"}},[_vm._v("Android")]),_c('b-form-checkbox',{attrs:{"state":_vm.state,"value":true},model:{value:(_vm.fetchData.ios),callback:function ($$v) {_vm.$set(_vm.fetchData, "ios", $$v)},expression:"fetchData.ios"}},[_vm._v("iOS")])],1),_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status","label-for":"name","state":errors.length > 0 ? false:null}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"status","options":_vm.statusOptions,"reduce":function (option) { return option.value; },"label":"label","placeholder":"Select Status"},model:{value:(_vm.fetchData.is_active),callback:function ($$v) {_vm.$set(_vm.fetchData, "is_active", $$v)},expression:"fetchData.is_active"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),(_vm.unity_versions)?_c('b-form-group',{attrs:{"label":"Unity Versions","label-for":"unity-versions"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"unity-versions","reduce":function (option) { return option.id; },"options":_vm.unity_versions,"label":"label","placeholder":"Select Unity Version","multiple":""},model:{value:(_vm.fetchData.unity_versions),callback:function ($$v) {_vm.$set(_vm.fetchData, "unity_versions", $$v)},expression:"fetchData.unity_versions"}})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"Token","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Token","label-for":"Token","rules":"required"}},[_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"3","max-rows":"6","disabled":""},model:{value:(_vm.fetchData.token),callback:function ($$v) {_vm.$set(_vm.fetchData, "token", $$v)},expression:"fetchData.token"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-3",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }