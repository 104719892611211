<template>
    <b-sidebar id="edit-build-server-sidebar" :visible="isEditBuildServerSidebarActive" bg-variant="white"
        sidebar-class="sidebar-lg" shadow backdrop no-header right
        @change="(val) => $emit('update:is-edit-build-server-sidebar-active', val)">
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Edit Build Server</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <validation-observer v-slot="{ handleSubmit }" ref="refFormObserver">
                <b-form ref="form" class="p-2" @submit.prevent="handleSubmit(onSubmit)">
                    <validation-provider #default="{ errors }" name="Server Name" rules="required">
                        <b-form-group label="Server Name" label-for="server-name">
                            <b-form-input id="server-name" v-model="editData.name" autofocus trim
                                :state="errors.length > 0 ? false:null" placeholder="Server Name" />
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="Server IP" rules="required">
                        <b-form-group label="Server Ip" label-for="server-ip">
                            <b-form-input id="server-ip" v-model="editData.ip" autofocus trim
                                :state="errors.length > 0 ? false:null" placeholder="Server Ip" />
                        </b-form-group>
                    </validation-provider>
                    <b-form-group label="Unity Username" label-for="unity-username">
                        <b-form-input id="unity-username" v-model="editData.unity_username" autofocus trim
                            placeholder="Unity Username" />
                    </b-form-group>
                    <b-form-group label="Unity Password" label-for="unity-password">
                        <b-form-input id="unity-password" v-model="editData.unity_password" autofocus trim
                            placeholder="Unity Password" />
                    </b-form-group>
                    <b-form-group label="Unity Serial" label-for="unity-serial">
                        <b-form-input id="unity-serial" v-model="editData.unity_serial" autofocus trim
                            placeholder="Unity Serial" />
                    </b-form-group>
                    <div class="mb-1">
                        <b-form-checkbox :state="state" v-model="editData.android" :value="true" class="mb-1">Android</b-form-checkbox>
                        <b-form-checkbox :state="state" v-model="editData.ios" :value="true">iOS</b-form-checkbox>
                    </div>
                    <validation-provider #default="{ errors }" name="Status" rules="required">
                        <b-form-group label="Status" label-for="name" :state="errors.length > 0 ? false:null">

                            <v-select id="status" v-model="editData.is_active"
                                      :options="statusOptions" :reduce="(option) => option.value"
                                      label="label" placeholder="Select Status" class="w-100">
                            </v-select>

                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider v-if="unity_versions" #default="{ errors }" name="Unity Versions" rules="required">
                        <b-form-group label="Unity Versions" label-for="unity-versions" :state="errors.length > 0 ? false:null">
                        <v-select id="unity-versions" 
                            v-model="editData.unity_versions" 
                            :reduce="(option) => option.id"
                            :options="unity_versions" 
                            label="label" 
                            placeholder="Select Unity Version" 
                            class="w-100" 
                            multiple>
                        </v-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <b-form-group label="Token" label-for="Token">
                        <b-form-textarea v-model="editData.token" rows="3" max-rows="6" disabled></b-form-textarea>
                    </b-form-group>
                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                            type="submit">
                            Update
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                            @click="hide">
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,BFormCheckbox
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
        BFormCheckbox
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isEditBuildServerSidebarActive',
        event: 'update:is-edit-build-server-sidebar-active',
    },
    props: {
        isEditBuildServerSidebarActive: {
            type: Boolean,
            required: true,
        },
        editData:{},
        unity_versions:{}
    },
    methods: {
        onSubmit() {
            if(!this.state) return
            this.$store.dispatch('buildServerStore/updateBuildServer', this.editData).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Successfully updated!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }).then(() => {
                this.$emit('refresh-data')
                this.$emit('update:is-edit-build-server-sidebar-active', false)
            }).catch((errors) => {
                console.log(errors)
            })
        }
    },
    data() {
        return {
            required,
            statusOptions: [
                {label: 'Active', value: 1},
                {label: 'Passive', value: 0},
            ],
        }
    },
    setup() {
        const {
            refFormObserver,
            getValidationState,
        } = formValidation()

        return {
            refFormObserver,
            getValidationState,
        }
    },computed: {
        state() {
            if(this.editData.ios || this.editData.android){
                this.stateError = false
                return true
            }else{
                this.stateError = true
                return false
            } 
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
