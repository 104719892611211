<template>
    <div>
        <build-server-create :is-create-build-server-sidebar-active.sync="isCreateBuildServerSidebarActive" :unity_versions="unityVersionList" @refresh-data="refetchData"/>
        <build-server-edit :is-edit-build-server-sidebar-active.sync="isEditBuildServerSidebarActive" :editData="editData" :unity_versions="unityVersionList" @refresh-data="refetchData"/>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 ml-1" />
                    </b-col>
                    <b-col cols="12" md="8">
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col cols="12" md="3">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                    placeholder="Search..." />
                            </b-col>
                            <b-col class="mb-md-0 mb-2" cols="12 " md="3" v-if="$can('read', 'buildServerCreate')">
								<b-button class="w-100" variant="primary" @click="isCreateBuildServerSidebarActive = true">
									<span class="text-nowrap">New Server</span>
								</b-button>
							</b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refDataListTable" class="position-relative" :items="fetchData" responsive
                :table-class="'build-list-table table dataTable no-footer dtr-column'" striped :fields="tableColumns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>
                <template #cell(name)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
                </template>
                <template #cell(ip)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.ip }}</span>
                </template>
                <template #cell(unity_versions)="data">
                    <b-badge pill class="text-capitalize mr-50" v-for="item in filterVersions(data.item.unity_versions) " :key="item.id">
        				{{ item.label }}
					</b-badge>
                </template>
                <template #cell(android)="data">
                    <b-badge pill class="text-capitalize mr-50" v-if="data.item.android" variant="light-success">
						<feather-icon icon="CheckIcon" size="12" />
					</b-badge>
                    <b-badge pill class="text-capitalize mr-50" v-else>
						<feather-icon icon="XIcon" size="12" />
					</b-badge>
                </template>
                <template #cell(ios)="data">
                    <b-badge pill class="text-capitalize mr-50" v-if="data.item.ios" variant="light-success">
						<feather-icon icon="CheckIcon" size="12" />
					</b-badge>
                    <b-badge pill class="text-capitalize mr-50" v-else>
						<feather-icon icon="XIcon" size="12" />
					</b-badge>
                </template>
                <template #cell(is_active)="data">
                    <b-badge v-if="data.item.is_active" variant="light-success">
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        <span>Active</span>
                    </b-badge>
                    <b-badge v-else-if="!data.item.is_active" variant="light-danger">
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        <span>Passive</span>
                    </b-badge>
                </template>
                <template #cell(token)="data">
                    <span class="align-text-top">{{ data.item.token }}</span>
                </template>
                <template #cell(updated_at)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.updated_at) }}</span>
                </template>
                <template #cell(actions)="data">
                    <b-link class="mr-1"
                            v-if="($can('read', 'buildServerEdit'))"
                            @click="fncEditSidebar(data.item.id)"
                            v-b-tooltip.hover.top="'Edit'">
                        <feather-icon icon="EditIcon" size="18"/>
                    </b-link>

                    <b-link class="mr-1"
                            v-if="($can('read', 'buildServerDelete'))"
                            v-b-tooltip.hover.top="'Delete'"
                            v-on:click="confirmDelete(data.item.id)">

                        <feather-icon icon="TrashIcon" size="18"/>
                    </b-link>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
                            entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import vSelect from "vue-select";
import useBuildServerApi from "@/composables/useBuildServerApi";
import useUnityEditorVersionApi from "@/composables/useUnityEditorVersionApi";

import BuildServerCreate from "@/views/buildServer/BuildServerCreate";
import BuildServerEdit from "@/views/buildServer/BuildServerEdit";
import {ref} from "@vue/composition-api";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BuildServerCreate,
        BuildServerEdit
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            loading: false,
        }
    },
    setup() {
        const isCreateBuildServerSidebarActive = ref(false)
        const isEditBuildServerSidebarActive = ref(false)
        const editData = ref(false)
        const {
            fetchData,
            refDataListTable,
            tableColumns,
            perPage,
            currentPage,
            totalData,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refetchData,
        } = useBuildServerApi()

        const {
            fetchDataVersions,
            unityVersionList
        } = useUnityEditorVersionApi()
        return {
            fetchData,
            refDataListTable,
            tableColumns,
            perPage,
            currentPage,
            totalData,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refetchData,
            isCreateBuildServerSidebarActive,
            isEditBuildServerSidebarActive,
            editData,
            fetchDataVersions,
            unityVersionList
        }
    },
    methods: {
        filterVersions(list){
            if(this.unityVersionList && list)return this.unityVersionList.filter(item => list.includes(item.id));
        },
        fncEditSidebar(id, type) {
            this.isEditBuildServerSidebarActive = true;
            this.editData = {}
            this.$store.dispatch('buildServerStore/fetchBuildServer', {id: id}).then((response) => {
                const {data} = response.data
                this.editData = data
            })
        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        confirmDelete(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to delete the server?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                        this.$store.dispatch('buildServerStore/deleteBuildServer', {
                            id: id
                        }).then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Successfully deleted!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                        }).then(() => {
                            this.refetchData()
                        })
                }
            })
        }
    },mounted(){
        this.fetchDataVersions()
    }
}

</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
